import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import * as Yup from 'yup';

import InputField from '../common/InputField';
import Button from '../common/Button';
import Link from '../common/Link';
import Alert from '../common/Alert';
import Modal from '../common/Modal';
import ModalContent from '../common/ModalContent';
import ModalActions from '../common/ModalActions';
import Snackbar from '../common/Snackbar';

import { useAuth } from '../../hooks/useAuth';
import useQueryString from '../../hooks/useQueryString';
import useAuthPersonalizations from '../../hooks/useAuthPersonalizations';

import { parseRequestError } from '../../utils/errors';
import * as apiClient from '../../apiClient';

function SignIn() {
  const history = useHistory();
  const location = useLocation();
  const { urlPerson } = useParams();
  const { user, signin, simulationAccess } = useAuth();
  const { personalizations, userNotFound } = useAuthPersonalizations(urlPerson);
  const [submitError, setSubmitError] = useState(null);
  const hasBgLogin = personalizations.bgLogin;
  const query = useQueryString();
  const simulationToken = query.get('stoken');

  useEffect(() => {
    if (user) {
      const { from } = location.state || { from: { pathname: '/player' } };
      history.replace({
        pathname: from.pathname,
      });
    }
  }, [history, user, location.state]);

  useEffect(() => {
    if (userNotFound) history.push('/login');
  }, [history, userNotFound]);

  useEffect(() => {
    let pageTitle = 'RadioSrv';
    if (personalizations.name) {
      pageTitle = `${personalizations.name} - Acesse sua rádio`;
    }
    document.title = pageTitle;
  }, [personalizations]);

  useEffect(() => {
    (async () => {
      if (!simulationToken) return;
      try {
        await simulationAccess(simulationToken);
      } catch (error) {
        setSubmitError(error.message);
        return;
      }
    })();
  }, [simulationToken, simulationAccess]);

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitError(null);

    try {
      await signin({
        login: values.login,
        password: values.password,
      });
      const { from } = location.state || { from: { pathname: '/player' } };
      history.replace(from);
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  if (user) return null;

  return (
    <div
      className={clsx(
        hasBgLogin ? 'lg:flex' : 'flex',
        'flex-col justify-center lg:flex-row h-full',
      )}
    >
      <div
        className={clsx(
          'flex justify-center',
          hasBgLogin ? 'lg:w-4/12 lg:max-w-2xl' : 'flex-1',
        )}
      >
        <div className="flex items-center justify-center px-4 py-6 sm:px-6 lg:px-8">
          <div className="w-full max-w-sm space-y-6">
            <div className="text-center lg:mb-20">
              <h1 className="font-bold text-4xl lg:text-4.5xl">
                Seja bem vindo!
              </h1>
              <p className="mt-5">
                Por favor, preencha os campos abaixo para entrar.
              </p>
            </div>
            <Formik
              initialValues={{ login: '', password: '' }}
              validationSchema={Yup.object({
                login: Yup.string().required('Informe o login da rádio.'),
                password: Yup.string().required('Informe a senha da rádio.'),
              })}
              onSubmit={handleSubmit}
            >
              <Form noValidate autoComplete="off">
                <InputField name="login" placeholder="Login" />
                <InputField
                  name="password"
                  type="password"
                  placeholder="Senha"
                />
                <div className="mb-5">
                  {submitError ? (
                    <Alert type="error">{submitError}</Alert>
                  ) : null}
                </div>
                <div className="mb-5 text-center">
                  <Button type="submit" size="large" color="primary" fullWidth>
                    Acessar
                  </Button>
                </div>
              </Form>
            </Formik>
            <PasswordRecovery />
          </div>
        </div>
      </div>
      {personalizations.bgLogin && (
        <div
          className="relative flex-1 bg-center bg-no-repeat bg-cover h-96 lg:h-auto"
          style={{ backgroundImage: `url(${personalizations.bgLogin})` }}
        >
          <div className="absolute bottom-0 flex items-end w-full bg-gradient-to-t from-black to-transparent h-3/6">
            <div className="p-6 lg:p-16 xl:p-24">
              {personalizations.text && (
                <h2 className="text-2xl font-bold lg:text-4xl">
                  {personalizations.text}
                </h2>
              )}
              {/* <h3 className="lg:text-lg lg:mt-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </h3> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function PasswordRecovery() {
  const [modalOpen, setModalOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleOpenModal = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setShowFeedback(false);
  };

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitError(null);

    try {
      await apiClient.recoverPassword({ login: values.login });
      setShowFeedback(true);
      handleCloseModal();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  return (
    <div className="mb-5">
      <div className="text-center">
        <Link size="sm" onClick={handleOpenModal}>
          Esqueceu sua senha?
        </Link>
      </div>
      <Snackbar open={showFeedback} onClose={handleCloseSnackbar}>
        <Alert type="success" className="shadow-md">
          <p className="font-bold">Sua requisição foi enviada com sucesso!</p>
          <p>Aguarde o contato por email.</p>
        </Alert>
      </Snackbar>
      <Modal open={modalOpen} onClose={handleCloseModal} size="small">
        <Formik
          initialValues={{ login: '' }}
          validationSchema={Yup.object({
            login: Yup.string().required('Informe o login da rádio.'),
          })}
          onSubmit={handleSubmit}
        >
          <Form noValidate autoComplete="off">
            <ModalContent>
              <div className="mb-10">
                <h2 className="mb-6 text-2xl font-bold">Recuperar senha</h2>
                <h3>
                  Informe o login da sua rádio no campo abaixo e, assim que
                  possível, entraremos em contato por e-mail.
                </h3>
              </div>
              <InputField name="login" placeholder="Login" noMargin />
              {submitError ? <Alert type="error">{submitError}</Alert> : null}
            </ModalContent>
            <ModalActions>
              <Button onClick={handleCloseModal}>Cancelar</Button>
              <Button color="primary" type="submit">
                Enviar
              </Button>
            </ModalActions>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
}

export default SignIn;
