import { useState, useEffect, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { FaEnvelope } from 'react-icons/fa';

import PageHeader from '../common/PageHeader';
import Button from '../common/Button';
import Alert from '../common/Alert';
import Label from '../common/Label';
import Snackbar from '../common/Snackbar';
import InputField from '../common/InputField';
import DateField from '../common/DateField';
import TextAreaField from '../common/TextAreaField';
import ConfirmDialog from '../common/ConfirmDialog';

import MailOpenIcon from '../../icons/MailOpen';
import TrashIcon from '../../icons/Trash';

import { formatDate } from '../../utils/datetime';
import { parseRequestError } from '../../utils/errors';
import YupCustom from '../../utils/validations';

import RadioContext from '../RadioContext';

import { useAuth } from '../../hooks/useAuth';
import * as apiClient from '../../apiClient';

function cleanHTMLText(message) {
  const cleanMessage = DOMPurify.sanitize(message, {
    USE_PROFILES: { html: true },
  });
  return parse(cleanMessage);
}

function Messages() {
  const [activeView, setActiveView] = useState('list');

  return (
    <div>
      <PageHeader title="Mensagens" />
      <div className="p-6">
        <div className="max-w-2xl mx-auto">
          <div className="flex">
            <Button
              color={activeView === 'list' ? 'primary' : 'secondary'}
              fullWidth
              onClick={() => setActiveView('list')}
            >
              Mensagens Recebidas
            </Button>
            <Button
              color={activeView === 'new' ? 'primary' : 'secondary'}
              fullWidth
              className="ml-5"
              onClick={() => setActiveView('new')}
            >
              Enviar Mensagem
            </Button>
          </div>
          <div className="mt-5">
            {activeView === 'list' && <MessageBox />}
            {activeView === 'new' && <SendMessage />}
          </div>
        </div>
      </div>
    </div>
  );
}

function SendMessage() {
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.sendMessage(values);
      setSubmitSuccess('Mensagem enviada com sucesso.');
      resetForm();
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        message: '',
        dateStart: '',
        dateEnd: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Informe um e-mail válido.'),
        message: Yup.string().required('Campo obrigatório.'),
        dateStart: YupCustom.date(),
        dateEnd: YupCustom.date().min(
          Yup.ref('dateStart'),
          'Data de fim deve ser maior que data de início.',
        ),
      })}
      onSubmit={handleSubmit}
    >
      <Form noValidate autoComplete="off">
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <InputField name="email" placeholder="Seu e-mail" />
        <TextAreaField
          name="message"
          placeholder="Digite sua mensagem aqui..."
          rows={10}
          maxChars={5000}
        />
        <div className="mb-5">
          <Label htmlFor="dateStart">Período</Label>
          <div className="flex justify-between">
            <div className="flex-1">
              <DateField name="dateStart" />
            </div>
            <div className="flex-1 pl-6">
              <DateField name="dateEnd" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end mb-5 text-center">
          <div>
            <Button type="submit" color="primary">
              Enviar
            </Button>
          </div>
        </div>
        <div className="mb-5">
          {submitError ? <Alert type="error">{submitError}</Alert> : null}
        </div>
      </Form>
    </Formik>
  );
}

function MessageBox() {
  const { user } = useAuth();
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  const { loadUnreadMessages } = useContext(RadioContext);

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null);
  };

  const loadMessages = async () => {
    const result = await apiClient.getMessages();
    setMessages(result.items);
  };

  useEffect(() => {
    loadMessages();
  }, []);

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setSubmitError(null);

    try {
      await apiClient.replyMessage(message.id, values);
      setSubmitSuccess('Mensagem enviada com sucesso.');
      resetForm();
      setMessage(null);
    } catch (error) {
      const [errorMessage, errorDetails] = parseRequestError(error);
      if (errorDetails) {
        setErrors(errorDetails);
      } else {
        setSubmitError(errorMessage);
      }
    }
  };

  if (message) {
    return (
      <Formik
        initialValues={{
          message: '',
        }}
        validationSchema={Yup.object({
          message: Yup.string().required('Campo obrigatório.'),
        })}
        onSubmit={handleSubmit}
      >
        <Form noValidate autoComplete="off">
          <div className="mb-5">
            <div className="p-4 overflow-y-scroll bg-black h-60">
              <Label className="mb-5 font-bold">
                {user.userName} escreveu:
              </Label>
              <p>{cleanHTMLText(message.message)}</p>
            </div>
          </div>
          <TextAreaField
            name="message"
            placeholder={`Responder à ${user.userName}...`}
            rows={6}
          />
          <div className="flex items-center justify-end mb-5 text-center">
            <div>
              <Button
                className="mr-4"
                onClick={() => {
                  setMessage(null);
                }}
              >
                Voltar
              </Button>
              <Button type="submit" color="primary">
                Responder
              </Button>
            </div>
          </div>
          <div className="mb-5">
            {submitError ? <Alert type="error">{submitError}</Alert> : null}
          </div>
        </Form>
      </Formik>
    );
  } else {
    return (
      <>
        <Snackbar open={Boolean(submitSuccess)} onClose={handleCloseSnackbar}>
          <Alert type="success" className="shadow-md">
            {submitSuccess}
          </Alert>
        </Snackbar>
        <div className="mb-5">
          {messages.length === 0 && <p>Nenhuma mensagem encontrada.</p>}
          {messages.map((message) => (
            <div
              key={message.id}
              className={
                'odd:bg-black rounded-md px-4 py-3 hover:bg-gray-800 transition-colors'
              }
            >
              <div
                className={clsx(
                  'flex justify-between',
                  !message.read && 'font-bold',
                )}
              >
                <div>Em {formatDate(message.date)}</div>
                <div className="flex items-center">
                  <button
                    className="w-4 text-gray-400 transition-colors hover:text-gray-500 focus:outline-none"
                    onClick={async () => {
                      if (!message.read) {
                        await apiClient.readMessage(message.id);
                        await loadMessages();
                        await loadUnreadMessages();
                      }
                      setMessage(message);
                    }}
                  >
                    {message.read ? <MailOpenIcon /> : <FaEnvelope />}
                  </button>
                  <ConfirmDialog
                    title="Remover mensagem"
                    message="Tem certeza que deseja remover esta mensagem?"
                    onConfirm={async () => {
                      await apiClient.deleteMessage(message.id);
                      await loadMessages();
                      await loadUnreadMessages();
                    }}
                  >
                    <button className="w-3 ml-4 text-gray-400 transition-colors hover:text-gray-500 focus:outline-none">
                      <TrashIcon />
                    </button>
                  </ConfirmDialog>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default Messages;
