import clsx from 'clsx';
import { useDebounce } from 'use-debounce';

function TextArea({ className, error = false, maxChars = null, ...props }) {
  const [totalChars] = useDebounce(props.value.length || 0, 50);

  return (
    <div className="relative">
      <textarea
        {...props}
        id={props.id || props.name}
        className={clsx(
          'w-full p-3 border-2 rounded-md focus:outline-none focus:border-gray-500 dark:bg-transparent dark:border-gray-400 dark:focus:border-gray-200',
          {
            'border-red-600 hover:border-red-600 focus:border-red-600 dark:border-red-600 dark:hover:border-red-600 dark:focus:border-red-600':
              error,
          },
          className,
        )}
      ></textarea>
      {maxChars > 0 && (
        <div className="absolute bottom-5 right-5 text-gray-400 text-sm font-medium">
          {totalChars}/{maxChars}
        </div>
      )}
    </div>
  );
}

export default TextArea;
