import {
  FaHome,
  FaSearch,
  FaVolumeDown,
  FaCogs,
  FaMusic,
  FaCalendarAlt,
  FaEnvelope,
} from 'react-icons/fa';

const mainMenuItems = [
  {
    id: 'home',
    title: 'Início',
    icon: <FaHome />,
    path: '/',
  },
  {
    id: 'pedido-musical',
    title: 'Busca',
    icon: <FaSearch />,
  },
  {
    id: 'chamadas',
    title: 'Chamadas',
    icon: <FaVolumeDown />,
    children: [
      {
        id: 'chamadas/funcionarios',
        title: 'Funcionários',
        path: '/chamadas/funcionarios',
      },
      {
        id: 'chamadas/aniversariantes',
        title: 'Aniversariantes',
        path: '/chamadas/aniversariantes',
      },
      {
        id: 'chamadas/instantaneas',
        title: 'Instantâneas',
        path: '/chamadas/instantaneas',
      },
      {
        id: 'chamadas/estacionamento',
        title: 'Estacionamento',
        path: '/chamadas/estacionamento',
      },
      {
        id: 'chamadas/locutor-virtual',
        title: 'Locutor Virtual',
        path: '/chamadas/locutor-virtual',
      },
      {
        id: 'chamadas/aviso-aula',
        title: 'Aviso de Aula',
        path: '/chamadas/aviso-aula',
      },
      {
        id: 'chamadas/aviso-onibus',
        title: 'Aviso de Ônibus',
        path: '/chamadas/aviso-onibus',
      },
      {
        id: 'chamadas/gerador-ofertas',
        title: 'Gerador de Ofertas',
      },
      {
        id: 'chamadas/banco-vozes',
        title: 'Banco de Vozes',
      },
      {
        id: 'chamadas/fluens',
        title: 'Fluens',
      },
    ],
  },
  {
    id: 'configuracoes',
    title: 'Config',
    icon: <FaCogs />,
    children: [
      {
        id: 'configuracoes/musicas-favoritas',
        title: 'Músicas Favoritas',
        path: '/configuracoes/musicas-favoritas',
      },
      {
        id: 'configuracoes/bloqueio-musicas',
        title: 'Bloqueio de Músicas',
        path: '/configuracoes/bloqueio-musicas',
      },
      {
        id: 'configuracoes/gerenciar-audios',
        title: 'Gerenciar Áudios',
        path: '/configuracoes/gerenciar-audios',
      },
      {
        id: 'configuracoes/conteudos',
        title: 'Conteúdos',
        path: '/configuracoes/conteudos',
      },
      {
        id: 'configuracoes/playlist-programacao',
        title: 'Playlist/Programação',
        path: '/configuracoes/playlist-programacao',
      },
      {
        id: 'configuracoes/eventos',
        title: 'Eventos',
        path: '/configuracoes/eventos',
      },
    ],
  },
  {
    id: 'musicas',
    title: 'Músicas',
    path: '/musicas',
    icon: <FaMusic />,
  },
  {
    id: 'historico',
    title: 'Histórico',
    path: '/historico',
    icon: <FaCalendarAlt />,
  },
  {
    id: 'mensagens',
    title: 'Mensagens',
    path: '/mensagens',
    icon: <FaEnvelope />,
  },
];

export function getMenuItems({ enabledModules }) {
  const enabledModulesMap = new Map();
  enabledModules.forEach((module) => {
    enabledModulesMap.set(module.id, module);
  });

  return mainMenuItems.reduce((items, item) => {
    if (item.id === 'home') {
      items.push(item);
      return items;
    }

    if (item.children && item.children.length > 0) {
      const children = [];
      item.children.forEach((child) => {
        if (enabledModulesMap.has(child.id)) {
          const extraParams = enabledModulesMap.get(child.id);
          children.push({ ...child, ...extraParams });
        }
      });
      if (children.length > 0) {
        items.push({ ...item, children });
      }
    } else {
      if (enabledModulesMap.has(item.id)) {
        const extraParams = enabledModulesMap.get(item.id);
        items.push({ ...item, ...extraParams });
      }
    }

    return items;
  }, []);
}

export default mainMenuItems;
